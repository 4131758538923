module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-js-fallback/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Raleway:400","Playfair Display:4000&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bouthouri website","short_name":"Bouthouri","start_url":"/","background_color":"#8eccff","theme_color":"#8eccff","display":"minimal-ui","icon":"src/assets/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b1003dda82cc41930b6a451d57eebfc4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
